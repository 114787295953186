import { WalletSignInResponse, WalletValidateResponse } from 'types/auth';
import axios from 'utils/axios';

export const authValidateApi = async (
  publicAddress: string
): Promise<WalletValidateResponse | undefined> => {
  const { data } = await axios.post('/auth/wallet/validate', { publicAddress });
  return data;
};

export const authSignInApi = async (
  publicAddress: string,
  signature: string
): Promise<WalletSignInResponse | undefined> => {
  const { data } = await axios.post('/auth/wallet/signin', { publicAddress, signature });
  return data;
};
