import { Box, Chip, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import DrawerHeader from 'components/@layout/main-layout/Drawer/DrawerHeader';
import config from 'config';
import environment from 'environments';
import { Link } from 'iconsax-react';
// import { useMemo } from 'react';
import { useSelector } from 'store';
import { MenuOrientation } from 'types/config';
import useWallet from 'utils/hooks/useWallet';
import { useSwitchChain } from 'wagmi';
import ConnectButton from './ConnectButton';
// import Localization from './Localization';
// import MegaMenuSection from './MegaMenuSection';
import Profile from './Profile';

const HeaderContent = () => {
  // const { i18n, menuOrientation } = config;
  const { menuOrientation } = config;
  const { user } = useSelector((state) => state.account);
  const { isSelectedSupportChain } = useWallet();
  const { switchChain } = useSwitchChain();
  const supportChain = environment.supportChains;

  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  // const localization = useMemo(() => <Localization />, [i18n]);

  // const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}

      {!!user ? (
        <>
          <Box sx={{ width: '100%', ml: { xs: 0, md: 2 } }}></Box>
          {/*!downLG && <Search />*/}
          {/*!downLG && megaMenu*/}
          {/*!downLG && localization*/}
          {downLG && <Box sx={{ width: '100%', ml: 1 }} />}

          {/*
           <Notification />
           <Message />
          */}
          {!isSelectedSupportChain && (
            <Box
              sx={{ mr: 1.5, cursor: 'pointer' }}
              onClick={() => switchChain({ chainId: supportChain[0].id })}
            >
              <Chip avatar={<Link variant="Bold" />} label="Unsupported Network" />
            </Box>
          )}

          <Profile />
        </>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <ConnectButton />
        </Box>
      )}
    </>
  );
};

export default HeaderContent;
