import { getLaunchpadApi } from 'apis/launchpad';
import LoadingContainer from 'components/@container/LoadingContainer';
import NotFoundContainer from 'components/@container/NotFoundContainer';
import { ERC20 } from 'contracts/erc-20';
import { Launchpad as LaunchpadContract } from 'contracts/launchpad';
import { isEmpty } from 'lodash';
import { createContext, PropsWithChildren, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Launchpad as LaunchpadOffChainInfo, LaunchpadStatus } from 'types/launchpad';
import { Vesting } from 'types/vesting';
import { toEther } from 'utils/format';
import useBoolean from 'utils/hooks/useBoolean';
import { useDeepEffect } from 'utils/hooks/useDeepEffect';
import useERC20Contract from 'utils/hooks/useERC20Contract';
import useLaunchpadContract from 'utils/hooks/useLaunchpadContract';
import useLaunchpadOwner from 'utils/hooks/useLaunchpadOwner';
import useLaunchpadStatus from 'utils/hooks/useLaunchpadStatus';
import { catchErrorSnackbar } from 'utils/snackbar';
import { calculateClaimCycle } from 'utils/vesting';
import { useAccount } from 'wagmi';

type LaunchpadOnChainInfo = {
  totalTokensAmount: number;
  totalTokensToBeChargedAmount: number;
  totalTokensBoughtAmount: number;
  totalPaymentReceivedAmount: number;
  saleType: number;
  isOverSoftCap: boolean;
  isEnded: boolean;
  isFinalized: boolean;
  canRetrieveLPToken: boolean;
  lpReleaseDate: number;
  buyerPaidAmount: number;
  participantLength: number;
  buyingCurrencyFeePercent: number;
  finalizeDate: number;
  currentClaimableTokenAmount: number;
};

type LaunchpadUtilityInfo = {
  isNativeCurrency: boolean;
  isOwner: boolean;
  status: LaunchpadStatus;
  vesting: Vesting | null;
};

type LaunchpadTokenInfo = {
  totalSupply: number;
};

type ViewLaunchpadContextType = {
  launchpadId: string;
  contract: LaunchpadContract;
  offChainInfo: LaunchpadOffChainInfo;
  onChainInfo: LaunchpadOnChainInfo;
  tokenInfo: LaunchpadTokenInfo;
  utilityInfo: LaunchpadUtilityInfo;
  updateOnChainInfo: () => Promise<void>;
};

const ViewLaunchpadContext = createContext<ViewLaunchpadContextType | null>(null);

function ViewLaunchpadContextProvider({ children }: PropsWithChildren) {
  const { launchpadId } = useParams();
  const { address } = useAccount();
  const [offChainInfo, setLaunchpadOffChainInfo] = useState<LaunchpadOffChainInfo>(
    {} as LaunchpadOffChainInfo
  );
  const [onChainInfo, setLaunchpadOnChainInfo] = useState<LaunchpadOnChainInfo>(
    {} as LaunchpadOnChainInfo
  );
  const [tokenInfo, setTokenInfo] = useState<LaunchpadTokenInfo>({} as LaunchpadTokenInfo);
  const [launchpadContract, setLaunchpadContract] = useState<LaunchpadContract>(
    {} as LaunchpadContract
  );
  const [erc20Contract, setERC20Contract] = useState<ERC20>({} as ERC20);
  const [isNotFound, setIsNotFound] = useBoolean(false);
  const { getContractInstant: getLaunchpadContractInstant } = useLaunchpadContract();
  const { getContractInstant: getERC20ContractInstant } = useERC20Contract();
  const [isBackendLoading, backendLoadingStart, backendLoadingDone] = useBoolean(true);
  const [isOnChainLoading, onChainLoadingStart, onChainLoadingDone] = useBoolean(true);

  const { isOwner } = useLaunchpadOwner(offChainInfo);
  const status = useLaunchpadStatus(offChainInfo.startTime, offChainInfo.endTime);

  useDeepEffect(() => {
    (async () => {
      try {
        backendLoadingStart();
        await updateOffChainInfo();
      } catch (error) {
        notFoundHandler();
        catchErrorSnackbar(error);
      } finally {
        backendLoadingDone();
      }
    })();
  }, [launchpadId]);

  useDeepEffect(() => {
    if (!isEmpty(offChainInfo)) {
      try {
        const launchpadContract = getLaunchpadContractInstant(offChainInfo.publicAddress);
        if (!launchpadContract) {
          throw new Error('`Launchpad Contract` Not found');
        }
        setLaunchpadContract(launchpadContract);
        const erc20Contract = getERC20ContractInstant(offChainInfo.tokenOnSale);
        if (!erc20Contract) {
          throw new Error('`ERC20 Contract` Not found');
        }
        setERC20Contract(erc20Contract);
      } catch (error) {
        notFoundHandler();
        catchErrorSnackbar(error);
      }
    }
  }, [offChainInfo]);

  useDeepEffect(() => {
    (async () => {
      if (!isEmpty(launchpadContract) && !isEmpty(erc20Contract)) {
        try {
          onChainLoadingStart();
          await Promise.all([updateOnChainInfo(), updateTokenInfo()]);
        } catch (error) {
          notFoundHandler();
          catchErrorSnackbar(error);
        } finally {
          onChainLoadingDone();
        }
      }
    })();
  }, [launchpadContract, erc20Contract]);

  const updateOffChainInfo = async () => {
    if (!launchpadId) {
      throw new Error('Launchpad not found');
    }
    const launchpadInfo = await getLaunchpadApi(launchpadId);
    if (!launchpadInfo) {
      throw new Error('`Launchpad Info` not found');
    }
    setLaunchpadOffChainInfo(launchpadInfo);
  };

  const updateOnChainInfo = async () => {
    const [
      totalTokensAmount,
      totalTokensToBeChargedAmount,
      totalTokensBoughtAmount,
      totalPaymentReceivedAmount,
      saleType,
      isOverSoftCap,
      isEnded,
      isFinalized,
      canRetrieveLPToken,
      lpReleaseDate,
      paidAmount,
      participantLength,
      buyingCurrencyFeePercent,
      finalizeDate,
      currentClaimableTokenAmount
    ] = await Promise.all([
      launchpadContract.totalTokensAmount(),
      launchpadContract.totalTokensToBeChargedAmount(),
      launchpadContract.totalTokensBoughtAmount(),
      launchpadContract.totalPaymentReceivedAmount(),
      launchpadContract.saleType(),
      launchpadContract.isOverSoftCap(),
      launchpadContract.isEnded(),
      launchpadContract.isFinalized(),
      launchpadContract.canRetrieveLPToken(),
      launchpadContract.lpReleaseDate(),
      !!address ? launchpadContract.paidAmount(`${address}`) : 0,
      launchpadContract.getParticipantLength(),
      launchpadContract.buyingCurrencyFeePercent(),
      launchpadContract.finalizeDate(),
      !!address ? launchpadContract.getCurrentClaimableTokenAmount(`${address}`) : 0
    ]);

    setLaunchpadOnChainInfo({
      totalTokensAmount: toEther(totalTokensAmount),
      totalTokensToBeChargedAmount: toEther(totalTokensToBeChargedAmount),
      totalTokensBoughtAmount: toEther(totalTokensBoughtAmount),
      totalPaymentReceivedAmount: toEther(totalPaymentReceivedAmount),
      saleType,
      isOverSoftCap,
      isEnded,
      isFinalized,
      canRetrieveLPToken,
      lpReleaseDate: lpReleaseDate.toNumber(),
      buyerPaidAmount: toEther(paidAmount),
      participantLength: participantLength.toNumber(),
      buyingCurrencyFeePercent: buyingCurrencyFeePercent.toNumber(),
      finalizeDate: finalizeDate.toNumber(),
      currentClaimableTokenAmount: toEther(currentClaimableTokenAmount)
    });
  };

  const updateTokenInfo = async () => {
    const bigTotalSupply = await erc20Contract.totalSupply();
    setTokenInfo({
      totalSupply: toEther(bigTotalSupply)
    });
  };

  const notFoundHandler = () => {
    setIsNotFound();
    backendLoadingDone();
    onChainLoadingDone();
  };

  const vesting = calculateClaimCycle(
    offChainInfo.useVesting,
    offChainInfo.firstBatchPercent,
    offChainInfo.cycleDays,
    offChainInfo.cycleBatchPercent,
    onChainInfo.finalizeDate
  );

  // const vesting = calculateClaimCycle(20, 30, 20, 1709259766);

  if (isBackendLoading || isOnChainLoading) {
    return <LoadingContainer loading={true} />;
  }

  return (
    <ViewLaunchpadContext.Provider
      value={{
        launchpadId: launchpadId!,
        contract: launchpadContract,
        offChainInfo,
        onChainInfo,
        tokenInfo,
        utilityInfo: {
          isNativeCurrency:
            offChainInfo.buyingCurrency === '0x0000000000000000000000000000000000000000',
          isOwner,
          status,
          vesting
        },
        updateOnChainInfo
      }}
    >
      <NotFoundContainer isNotFound={isNotFound}>{children}</NotFoundContainer>
    </ViewLaunchpadContext.Provider>
  );
}

export { ViewLaunchpadContextProvider, ViewLaunchpadContext };
