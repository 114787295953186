import { CircularProgress, SxProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import BoxCenter from './BoxCenter';

type Props = {
  loading: boolean;
  height?: number;
  size?: number;
  sx?: SxProps;
};

export default function LoadingContainer({
  children,
  loading,
  height = 300,
  size = 64,
  sx
}: PropsWithChildren<Props>) {
  if (loading) {
    return (
      <BoxCenter
        sx={{
          minHeight: height,
          width: '100%',
          ...sx
        }}
      >
        <CircularProgress size={size} disableShrink thickness={3} />
      </BoxCenter>
    );
  }

  return <>{children}</>;
}
