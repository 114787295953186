import development from './development';
import production from './production';
import test from './test';

const mode = process.env.REACT_APP_MODE;

let environment = development;
// log mode to console
console.log('mode:', mode);
if (mode === 'test') environment = test;
if (mode === 'production') environment = production;

export default environment;
