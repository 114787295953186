import { providers } from 'ethers';
import { useMemo } from 'react';
import { Config, useConnectorClient } from 'wagmi';
import useWallet from './useWallet';

export default function useSigner() {
  const { data: client } = useConnectorClient<Config>();
  const { isSelectedSupportChain } = useWallet();

  return useMemo(() => {
    if (!client || !isSelectedSupportChain) return null;
    const { account, chain, transport } = client;
    const network = {
      chainId: chain.id,
      name: chain.name,
      ensAddress: chain.contracts?.ensRegistry?.address
    };
    const provider = new providers.Web3Provider(transport, network);
    const signer = provider.getSigner(account.address);
    return signer;
  }, [client]);
}
