import { LoadingButtonProps } from '@mui/lab';
import { useTheme } from '@mui/material';
import { motion, useCycle } from 'framer-motion';
import LoadingButton from './LoadingButton';

export type ScaleProps = {
  hover: number | string | undefined;
  tap: number | string | undefined;
};

export interface Props extends LoadingButtonProps {
  animateType?: 'slide' | 'scale' | 'rotate';
  direction?: 'up' | 'down' | 'left' | 'right';
  offset?: number;
  scale?: ScaleProps;
}

export default function AnimateButton({
  children,
  animateType = 'scale',
  direction = 'right',
  offset = 10,
  scale = { hover: 1.05, tap: 0.954 },
  ...rest
}: Props) {
  const theme = useTheme();

  let offset1;
  let offset2;
  switch (direction) {
    case 'up':
    case 'left':
      offset1 = offset;
      offset2 = 0;
      break;
    case 'right':
    case 'down':
    default:
      offset1 = 0;
      offset2 = offset;
      break;
  }

  const [x, cycleX] = useCycle(offset1, offset2);
  const [y, cycleY] = useCycle(offset1, offset2);

  const Button = (
    <LoadingButton
      variant="contained"
      {...rest}
      sx={{
        '&.Mui-disabled': {
          background: theme.palette.secondary[200],
          color: theme.palette.secondary.light
        }
      }}
    >
      {children}
    </LoadingButton>
  );

  switch (animateType) {
    case 'rotate':
      return (
        <motion.div
          animate={{ rotate: 360 }}
          transition={{
            repeat: Infinity,
            repeatType: 'loop',
            duration: 2,
            repeatDelay: 0
          }}
        >
          {Button}
        </motion.div>
      );
    case 'slide':
      if (direction === 'up' || direction === 'down') {
        return (
          <motion.div
            animate={{ y: y !== undefined ? y : '' }}
            onHoverEnd={() => cycleY()}
            onHoverStart={() => cycleY()}
          >
            {Button}
          </motion.div>
        );
      }
      return (
        <motion.div
          animate={{ x: x !== undefined ? x : '' }}
          onHoverEnd={() => cycleX()}
          onHoverStart={() => cycleX()}
        >
          {Button}
        </motion.div>
      );

    case 'scale':
    default:
      if (typeof scale === 'number') {
        scale = {
          hover: scale,
          tap: scale
        };
      }
      return (
        <motion.div whileHover={{ scale: scale?.hover }} whileTap={{ scale: scale?.tap }}>
          {Button}
        </motion.div>
      );
  }
}
