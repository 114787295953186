import { Airdrop, CreateAirdropPayload } from 'types/airdrop';
import { PageInfo } from 'types/root';
import axios from 'utils/axios';

export const createAirdropApi = async (
  payload: CreateAirdropPayload
): Promise<Airdrop | undefined> => {
  const { data } = await axios.post('/airdrops', { ...payload });
  return data;
};

export const getAllAirdropsApi = async (
  params?: string
): Promise<PageInfo<Airdrop[]> | undefined> => {
  const { data } = await axios.get(`/airdrops${params}`);
  return data;
};

export const getMyOwnAirdropsApi = async (
  params?: string
): Promise<PageInfo<Airdrop[]> | undefined> => {
  const { data } = await axios.get(`/airdrops/me/owner${params}`);
  return data;
};

export const getMyAllocationAirdropsApi = async (
  params?: string
): Promise<PageInfo<Airdrop[]> | undefined> => {
  const { data } = await axios.get(`/airdrops/me/allocation${params}`);
  return data;
};

export const getAirdropsApi = async (airdropId: string): Promise<Airdrop | undefined> => {
  const { data } = await axios.get(`/airdrops/${airdropId}`);
  return data;
};

export const addAllocationsAirdropsApi = async (
  airdropId: string,
  allocations: string[]
): Promise<boolean | undefined> => {
  const { data } = await axios.post(`/airdrops/${airdropId}/add-allocations`, { allocations });
  return data;
};

export const removeAllocationsAirdropsApi = async (
  airdropId: string,
  allocations: string[]
): Promise<boolean | undefined> => {
  const { data } = await axios.post(`/airdrops/${airdropId}/remove-allocations`, { allocations });
  return data;
};
