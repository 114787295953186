import { Chain } from 'viem';

export const bkcTestnet: Chain = {
  id: 25925,
  name: 'Bitkub Chain Testnet',
  nativeCurrency: { name: 'tKUB', symbol: 'tKUB', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc-testnet.bitkubchain.io'],
      webSocket: ['wss://wss-testnet.bitkubchain.io']
    }
  },
  blockExplorers: {
    default: {
      name: 'bkcscan',
      url: 'https://testnet.bkcscan.com',
      apiUrl: 'https://testnet.bkcscan.com/api'
    }
  },
  testnet: true
};

export const jib: Chain = {
  id: 8899,
  name: 'JIBCHAIN L1',
  nativeCurrency: { name: 'JBC', symbol: 'JBC', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc-l1.jibchain.net']
    }
  },
  blockExplorers: {
    default: {
      name: 'JIBCHAIN explorer',
      url: 'https://exp-l1.jibchain.net',
      apiUrl: 'https://exp-l1.jibchain.net/api'
    }
  }
};
