import { enqueueSnackbar } from 'notistack';
import { errorMessage } from './error-format';

export const catchErrorSnackbar = (error: unknown) => {
  const message = errorMessage(error).toString() || '';

  const trimMessage = message.length > 60 ? `${message.substring(1, 60)}...` : message;

  enqueueSnackbar(trimMessage, {
    variant: 'error',
    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    autoHideDuration: 3000
  });
};

export const successSnackbar = (message: string) => {
  enqueueSnackbar(message, {
    variant: 'success',
    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    autoHideDuration: 3000
  });
};
