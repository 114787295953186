import { Addresses } from 'types/root';
import { bkcTestnet, jib } from 'utils/constants/chain';
import { Chain } from 'viem';

const launchpadFactoryAddresses: Addresses = {
  25925: '0xf2222529895CA04F3aF600a50b643B16D8aaCcB2',
  8899: '0x512f0B2155C8F1b31F287CAbE1F99bE5dB24d0Dd'
};

const tokenFactoryAddresses: Addresses = {
  25925: '0x4FE4Ef002eCa06f85BcAF047516aed3eb0c8A138',
  8899: '0x29824A60E7B4C42B9368E0C1a4C04F9250fDD573'
};

const callHelperAddresses: Addresses = {
  25925: '0x26339e699eB3b5F12342CdC7ff71A1b4A327d656',
  8899: '0x22Af56B212d4896DccDBA9a1E50Aa95C4364B0db'
};

const airdropFactoryAddresses: Addresses = {
  25925: '0x6D82D1c515BC40ccB53CF863f5E8bC33d3286a8F',
  8899: '0x4A642317559Af6B8025dB0490455ce7580F38934'
};

const supportChains: [Chain, ...Chain[]] = [bkcTestnet, jib];

const environment = {
  mode: process.env.REACT_APP_MODE,
  supportChains,
  apiUrl: 'https://asia-southeast1-snowpad-13fec.cloudfunctions.net/testnet',
  walletConnectProjectId: 'd58202e3f0854741f196484c107aef01',
  launchpadFactoryAddresses,
  tokenFactoryAddresses,
  callHelperAddresses,
  airdropFactoryAddresses,
  documentUrl: 'https://snowpad.gitbook.io/document',
  chainRpcUrl: 'https://rpc-testnet.bitkubchain.io',
  fee: 1
};

export default environment;
