import AuthPortal from 'components/@wrapper/AuthPortal';
import { CombineComponents } from 'components/@wrapper/CombineComponents';
import FullScreenLoaderPortal from 'components/@wrapper/FullScreenLoaderPortal';
import MuiLocalizationProvider from 'components/@wrapper/MuiLocalizationProvider';
import Notistack from 'components/@wrapper/Notistack';
import ReduxProvider from 'components/@wrapper/ReduxProvider';
import ScrollTopPortal from 'components/@wrapper/ScrollTopPortal';
import SnackbarPortal from 'components/@wrapper/SnackbarPortal';
import WagmiConfig from 'components/@wrapper/WagmiConfig';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes';
import MuiThemeCustomization from 'themes';

const providers = [
  ReduxProvider,
  Notistack,
  FullScreenLoaderPortal,
  WagmiConfig,
  AuthPortal,
  BrowserRouter,
  MuiThemeCustomization,
  MuiLocalizationProvider,
  ScrollTopPortal,
  SnackbarPortal
];
const AppProvider = CombineComponents(...providers);

const App = () => {
  return (
    <AppProvider>
      <Routes />
    </AppProvider>
  );
};

export default App;
