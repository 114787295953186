import { useWeb3Modal, useWeb3ModalState } from '@web3modal/wagmi/react';
import { useDispatch } from 'store';
import { clearUserInfo } from 'store/reducers/account';
import { setCredentialTokens } from 'utils/local-storage';
import { useChains, useDisconnect } from 'wagmi';

export default function useWallet() {
  const { open } = useWeb3Modal();
  const { selectedNetworkId } = useWeb3ModalState();
  const chains = useChains();
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();

  const connectWallet = () => {
    open({ view: 'Networks' });
  };

  const disconnectWallet = () => {
    dispatch(clearUserInfo());
    setCredentialTokens();
    disconnect();
  };

  const isSelectedSupportChain = chains.some(
    (chain) => chain.id === (selectedNetworkId as unknown as number)
  );

  return { connectWallet, disconnectWallet, isSelectedSupportChain };
}
