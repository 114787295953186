import moment from 'moment';
import { ClaimCycle } from 'types/vesting';

export const calculateClaimCycle = (
  useVesting: boolean,
  firstBatchPercent: number,
  cycleDays: number,
  cycleBatchPercent: number,
  finalizeDate: number
) => {
  if (!finalizeDate || !useVesting) {
    return null;
  }
  const claimCycle: ClaimCycle[] = [];

  let currentPercentage: number = firstBatchPercent;
  let totalPercentage: number = 0;
  let numberOfClaims: number = 0;
  let currentDate = moment.unix(finalizeDate);

  let isFirstCycle = true;
  while (totalPercentage < 100) {
    const daysToAdd: number = cycleDays * numberOfClaims;
    const futureDate = currentDate.clone().add(daysToAdd, 'days');

    totalPercentage += currentPercentage;
    const claimPercentage = currentPercentage;

    if (isFirstCycle) {
      currentPercentage = cycleBatchPercent;
      isFirstCycle = false;
    }

    numberOfClaims++;

    let percentage = claimPercentage;
    if (totalPercentage > 100) {
      const excess = totalPercentage - 100;
      totalPercentage = 100;
      percentage -= excess;
    }

    claimCycle.push({
      no: numberOfClaims,
      time: futureDate,
      percentage,
      totalPercentage,
      isPassed: moment().isAfter(futureDate)
    });
  }

  const upcomingCycleIndex = findUpcomingCycleIndex(claimCycle);
  const currentCycleIndex = upcomingCycleIndex - 1;

  return {
    claimCycle,
    upcomingCycleNo: upcomingCycleIndex + 1,
    currentCycleNo: currentCycleIndex + 1,
    allCycle: claimCycle.length
  };
};

const findUpcomingCycleIndex = (claimSteps: ClaimCycle[]) => {
  const isAllPassed = claimSteps.every((item) => item.isPassed);
  if (isAllPassed) {
    return claimSteps.length;
  }
  const reverseClaimSteps = [...claimSteps].reverse();
  const lastIndexBeforeNow = reverseClaimSteps.findIndex((step) => step.isPassed);
  return claimSteps.length - lastIndexBeforeNow;
};
