import { combineReducers } from 'redux';
import account from './account';
import loader from './loader';
import menu from './menu';
import snackbar from './snackbar';

const reducers = combineReducers({
  account,
  menu,
  snackbar,
  loader
});

export default reducers;
