import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type LoaderReducerState = {
  fullScreen: boolean;
};

const initialState: LoaderReducerState = {
  fullScreen: false
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    toggleFullScreenLoader: (state, action: PayloadAction<boolean>) => ({
      ...state,
      fullScreen: action.payload
    })
  }
});

export const { toggleFullScreenLoader } = loaderSlice.actions;
export default loaderSlice.reducer;
