import { getAirdropsApi } from 'apis/airdrop';
import LoadingContainer from 'components/@container/LoadingContainer';
import NotFoundContainer from 'components/@container/NotFoundContainer';
import { Airdrop as AirdropContract } from 'contracts/airdrop';
import { ERC20 } from 'contracts/erc-20';
import { isEmpty } from 'lodash';
import { createContext, PropsWithChildren, useState } from 'react';
import { useParams } from 'react-router';
import { Airdrop as AirdropOffChainInfo, AirdropStatus } from 'types/airdrop';
import { Vesting } from 'types/vesting';
import { toEther, toPercent } from 'utils/format';
import useAirdropContract from 'utils/hooks/useAirdropContract';
import useBoolean from 'utils/hooks/useBoolean';
import { useDeepEffect } from 'utils/hooks/useDeepEffect';
import useERC20Contract from 'utils/hooks/useERC20Contract';
import useSigner from 'utils/hooks/useSigner';
import { catchErrorSnackbar } from 'utils/snackbar';
import { calculateClaimCycle } from 'utils/vesting';
import { useAccount } from 'wagmi';

type AirdropOnChainInfo = {
  totalDropTokenAmount: number;
  participantLength: number;
  isFinalized: boolean;
  participantClaimableAmount: number;
  participantClaimed: number;
  useVesting: boolean;
  firstBatchPercent: number;
  cycleDays: number;
  cycleBatchPercent: number;
  finalizeDate: number;
  dropPerUser: number;
  maxUser: number;
  isParticipant: boolean;
  owner: string;
  airdropType: number;
  tokenNeededAmount: number;
  currentClaimableTokenAmount: number;
};

type ViewAirdropContextType = {
  airdropId: string;
  airdropContract: AirdropContract;
  erc20Contract: ERC20;
  offChainInfo: AirdropOffChainInfo;
  onChainInfo: AirdropOnChainInfo;
  utilityInfo: AirdropUtilityInfo;
  updateOnChainInfo: () => Promise<void>;
  handleToggleReload: () => void;
  reloadToggle: boolean;
};

type AirdropUtilityInfo = {
  isOwner: boolean;
  status: AirdropStatus;
  vesting: Vesting | null;
  isMaxUserReached: boolean;
};

const ViewAirdropContext = createContext<ViewAirdropContextType | null>(null);

function ViewAirdropContextProvider({ children }: PropsWithChildren) {
  const { airdropId } = useParams();
  const { address } = useAccount();
  const signer = useSigner();

  const [offChainInfo, setOffChainInfo] = useState<AirdropOffChainInfo>({} as AirdropOffChainInfo);
  const [onChainInfo, setOnChainInfo] = useState<AirdropOnChainInfo>({} as AirdropOnChainInfo);

  const [airdropContract, setAirdropContract] = useState<AirdropContract>({} as AirdropContract);
  const [erc20Contract, setERC20Contract] = useState<ERC20>({} as ERC20);

  const [isNotFound, setIsNotFound] = useBoolean(false);
  const { getContractInstant: getAirdropContractInstant } = useAirdropContract();
  const { getContractInstant: getERC20ContractInstant } = useERC20Contract();
  const [isBackendLoading, backendLoadingStart, backendLoadingDone] = useBoolean(true);
  const [isOnChainLoading, onChainLoadingStart, onChainLoadingDone] = useBoolean(true);
  const [reloadToggle, setReloadToggle] = useState(false);

  useDeepEffect(() => {
    (async () => {
      try {
        backendLoadingStart();
        await updateOffChainInfo();
      } catch (error) {
        notFoundHandler();
        catchErrorSnackbar(error);
      } finally {
        backendLoadingDone();
      }
    })();
  }, [airdropId]);

  useDeepEffect(() => {
    if (!isEmpty(offChainInfo)) {
      try {
        const airdropContract = getAirdropContractInstant(offChainInfo.airdropAddress);
        if (!airdropContract) {
          throw new Error('`Launchpad Contract` Not found');
        }
        setAirdropContract(airdropContract);

        const erc20Contract = getERC20ContractInstant(offChainInfo.tokenAddress);
        if (!erc20Contract) {
          throw new Error('`ERC20 Contract` Not found');
        }
        setERC20Contract(erc20Contract);
      } catch (error) {
        notFoundHandler();
        catchErrorSnackbar(error);
      }
    }
  }, [offChainInfo, signer]);

  useDeepEffect(() => {
    (async () => {
      if (!isEmpty(airdropContract)) {
        try {
          onChainLoadingStart();
          await updateOnChainInfo();
        } catch (error) {
          notFoundHandler();
          catchErrorSnackbar(error);
        } finally {
          onChainLoadingDone();
        }
      }
    })();
  }, [airdropContract]);

  const updateOffChainInfo = async () => {
    if (!airdropId) {
      throw new Error('Airdrop not found');
    }
    const airdropInfo = await getAirdropsApi(airdropId);
    if (!airdropInfo) {
      throw new Error('`Airdrop Info` not found');
    }
    setOffChainInfo(airdropInfo);
  };

  const updateOnChainInfo = async () => {
    const [
      bigTotalDropTokenAmount,
      bigParticipantLength,
      isFinalized,
      bigparticipantClaimableAmount,
      bigparticipantClaimed,
      useVesting,
      firstBatchPercent,
      cycleDays,
      cycleBatchPercent,
      bigFinalizeDate,
      bigDropPerUser,
      bigMaxUser,
      isParticipant,
      owner,
      airdropType,
      bigTokenNeededAmount,
      bigCurrentClaimableTokenAmount
    ] = await Promise.all([
      airdropContract.totalDropTokenAmount(),
      airdropContract.getParticipantLength(),
      airdropContract.isFinalized(),
      !!address ? airdropContract.claimableAmount(`${address}`) : 0,
      !!address ? airdropContract.claimed(`${address}`) : 0,
      airdropContract.useVesting(),
      airdropContract.firstBatchPercent(),
      airdropContract.cycleDays(),
      airdropContract.cycleBatchPercent(),
      airdropContract.finalizeDate(),
      airdropContract.dropPerUser(),
      airdropContract.maxUser(),
      !!address ? airdropContract.isParticipant(`${address}`) : false,
      airdropContract.owner(),
      airdropContract.airdropType(),
      airdropContract.getTokenNeededAmount(),
      !!address ? airdropContract.getCurrentClaimableTokenAmount(`${address}`) : 0
    ]);

    setOnChainInfo({
      totalDropTokenAmount: toEther(bigTotalDropTokenAmount),
      participantLength: bigParticipantLength.toNumber(),
      isFinalized,
      participantClaimableAmount: toEther(bigparticipantClaimableAmount),
      participantClaimed: toEther(bigparticipantClaimed),
      useVesting,
      firstBatchPercent: toPercent(firstBatchPercent),
      cycleDays,
      cycleBatchPercent: toPercent(cycleBatchPercent),
      finalizeDate: bigFinalizeDate.toNumber(),
      dropPerUser: toEther(bigDropPerUser),
      maxUser: bigMaxUser.toNumber(),
      isParticipant,
      owner,
      airdropType,
      tokenNeededAmount: toEther(bigTokenNeededAmount),
      currentClaimableTokenAmount: toEther(bigCurrentClaimableTokenAmount)
    });
  };

  const notFoundHandler = () => {
    setIsNotFound();
    backendLoadingDone();
    onChainLoadingDone();
  };

  const vesting = calculateClaimCycle(
    onChainInfo.useVesting,
    onChainInfo.firstBatchPercent,
    onChainInfo.cycleDays,
    onChainInfo.cycleBatchPercent,
    onChainInfo.finalizeDate
  );

  if (isBackendLoading || isOnChainLoading) {
    return <LoadingContainer loading={true} />;
  }

  return (
    <ViewAirdropContext.Provider
      value={{
        airdropId: airdropId!,
        airdropContract,
        erc20Contract,
        offChainInfo,
        onChainInfo,
        utilityInfo: {
          isOwner: onChainInfo.owner === address,
          vesting,
          status: onChainInfo.isFinalized ? 2 : 1,
          isMaxUserReached: onChainInfo.participantLength >= onChainInfo.maxUser
        },
        updateOnChainInfo,
        reloadToggle,
        handleToggleReload: () => setReloadToggle((prev) => !prev)
      }}
    >
      <NotFoundContainer isNotFound={isNotFound}>{children}</NotFoundContainer>
    </ViewAirdropContext.Provider>
  );
}

export { ViewAirdropContextProvider, ViewAirdropContext };
