import axios from 'axios';
import environment from 'environments';

const axiosServices = axios.create({ baseURL: environment.apiUrl });

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && !window.location.href.includes('/')) {
      window.location.pathname = '/';
    }
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  }
);

export default axiosServices;
