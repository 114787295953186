import { ERC20__factory } from 'contracts/erc-20';
import useSigner from './useSigner';
import useWeb3Provider from './useWeb3Provider';

export default function useERC20Contract() {
  const signer = useSigner();
  const provider = useWeb3Provider();
  const signerOrProvider = signer || provider;

  const getContractInstant = (address: string) => {
    if (!signerOrProvider && !address) {
      return null;
    }
    const launchpad = ERC20__factory.connect(address, signerOrProvider);
    return launchpad;
  };

  return { getContractInstant };
}
