import AnimateButton from 'components/@button/AnimateButton';
import { Wallet3 } from 'iconsax-react';
import useWallet from 'utils/hooks/useWallet';

export default function ConnectButton() {
  const { connectWallet } = useWallet();
  return (
    <AnimateButton
      sx={{ minWidth: 160 }}
      disableElevation
      startIcon={<Wallet3 />}
      onClick={() => connectWallet()}
    >
      Connect Wallet
    </AnimateButton>
  );
}
