import { CircularProgress, Typography } from '@mui/material';
import BaseModal from 'components/@container/BaseModal';
import BoxCenter from 'components/@container/BoxCenter';
import { PropsWithChildren } from 'react';
import { useSelector } from 'store';

export default function FullScreenLoaderPortal({ children }: PropsWithChildren) {
  const { fullScreen } = useSelector((state) => state.loader);
  return (
    <>
      <BaseModal open={fullScreen} disablePadding>
        <BoxCenter sx={{ width: 300, height: 180, flexDirection: 'column', gap: 3 }}>
          <CircularProgress />
          <Typography>Please, do not close the window</Typography>
        </BoxCenter>
      </BaseModal>

      {children}
    </>
  );
}
