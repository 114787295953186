import { Launchpad__factory } from 'contracts/launchpad';
import useSigner from './useSigner';
import useWeb3Provider from './useWeb3Provider';

export default function useLaunchpadContract() {
  const signer = useSigner();
  const provider = useWeb3Provider();

  const getContractInstant = (address: string) => {
    if (!provider && !address) {
      return null;
    }
    const signerOrProvider = signer || provider;
    const launchpad = Launchpad__factory.connect(address, signerOrProvider);
    return launchpad;
  };

  return { getContractInstant };
}
