import environment from 'environments';
import { providers } from 'ethers';

export default function useWeb3Provider() {
  //   let web3Provider;
  //   if (typeof window !== 'undefined' && (window as any).ethereum) {
  //     web3Provider = new providers.Web3Provider(window.ethereum, 'any');
  //   } else {
  //     // in case : browser doesn't have ethereum or metamask
  //     const rpcUrl = environment.chainRpcUrl;
  //     web3Provider = new providers.JsonRpcProvider(rpcUrl);
  //   }

  const rpcUrl = environment.chainRpcUrl;
  const web3Provider = new providers.JsonRpcProvider(rpcUrl);

  return web3Provider;
}
