// material-ui
//import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //
interface Props {
  height?: number;
}

const LogoIcon = ({ height }: Props) => {
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoIconDark : logoIcon} alt="icon logo" width="100" />
     *
     */
    <svg
      height={height ? height : 27}
      viewBox="0 0 61 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_17_4)">
        <path
          d="M34.2005 39.3634C25.3789 35.3535 21.695 26.271 26.383 25.1018C33.3907 23.3568 37.0567 41.2783 60.9974 38.7547C60.9974 38.7547 51.3762 36.4999 45.0097 27.8892C37.5191 17.7617 20.7472 10.3177 18.6267 19.4002C14.9632 35.0897 34.5505 39.5207 34.2005 39.3609V39.3634Z"
          fill="url(#paint0_linear_17_4)"
        />
        <path
          d="M29.9877 22.1318C29.9877 22.1318 32.4761 22.6314 36.8268 26.1949C40.3805 29.1041 43.6378 32.107 49.5597 32.6524C49.5597 32.6524 46.7802 30.1363 46.1083 29.1066C45.4364 28.0769 36.0323 22.4361 29.9852 22.1343L29.9877 22.1318Z"
          fill="#3CA1CB"
        />
        <path
          d="M34.2005 39.3634C34.2005 39.3634 26.1658 36.0992 23.5063 29.7356C21.5212 24.9851 25.7724 20.7825 31.3622 22.4488C39.6013 24.9065 43.0579 32.8629 55.4561 29.1041C55.4561 29.1041 52.2039 27.1334 49.7769 24.9192C45.6203 21.1299 39.2104 12.6105 27.2413 13.5819C10.661 30.3164 29.5304 37.6641 34.2031 39.3634H34.2005Z"
          fill="url(#paint1_linear_17_4)"
        />
        <path
          d="M26.8122 19.8871C26.8122 19.8871 29.6224 18.6139 36.2776 21.0386C42.9327 23.4633 44.7517 23.7829 48.5992 23.7829C47.332 22.6441 46.5043 21.6549 45.1937 20.1306C45.1937 20.1306 33.102 16.1892 26.8096 19.8871H26.8122Z"
          fill="#87CAF0"
        />
        <path
          d="M34.2006 39.3634C34.2006 39.3634 22.5943 34.7372 21.5571 27.1029C20.6629 20.5187 28.2148 18.2512 34.8112 19.948C50.5204 23.9909 55.4536 15.824 55.8215 14.3707C55.8215 14.3707 52.204 16.4023 43.0426 13.8558C36.3108 11.984 19.6052 13.3714 19.3165 23.2224C19.2271 26.238 18.7673 35.6198 34.2031 39.3634H34.2006Z"
          fill="url(#paint2_linear_17_4)"
        />
        <path
          d="M26.079 16.7954C26.079 16.7954 29.8345 15.4917 36.3389 15.7961C42.8433 16.1004 46.5988 14.675 46.5988 14.675C46.5988 14.675 42.782 13.7949 41.8035 13.5844C40.825 13.3739 40.2758 13.1913 37.7414 13.1913C35.2071 13.1913 28.2454 15.1316 26.0764 16.7979L26.079 16.7954Z"
          fill="#7CCDF2"
        />
        <path
          d="M34.2005 39.3634C34.2005 39.3634 16.3658 33.5299 20.5198 21.6473C23.9892 11.7177 45.071 19.3444 48.6145 4.36752C48.6145 4.36752 47.0254 6.97484 27.2388 8.00458C7.44966 9.03432 6.71899 36.0282 34.2005 39.3634Z"
          fill="url(#paint3_linear_17_4)"
        />
        <path
          d="M37.9892 7.09402C37.9892 7.09402 37.187 8.6716 28.2479 10.4901C28.2479 10.4901 22.6454 11.5655 19.7738 13.4449C19.7738 13.4449 21.2556 10.6702 24.6151 9.5796C27.9746 8.48899 30.6009 7.76107 33.3498 7.51759C36.0987 7.2741 37.9918 7.09402 37.9918 7.09402H37.9892Z"
          fill="#5BB9E4"
        />
        <path
          d="M25.1618 3.57618C16.3964 5.89944 5.53616 12.159 10.1373 25.221C15.5355 40.5478 34.2006 39.3609 34.2006 39.3609C14.8764 35.8912 12.8249 17.2773 21.1304 12.6714C24.983 10.5333 31.1732 9.2296 33.3447 7.5176C37.1922 4.48672 37.2535 0 37.2535 0C33.5287 2.06201 30.1564 2.25477 25.1618 3.57618Z"
          fill="url(#paint4_linear_17_4)"
        />
        <path
          d="M16.4169 7.26395C16.4169 7.26395 7.86099 16.2298 14.7307 28.508C14.7307 28.508 9.60333 21.3582 9.60333 15.8087C9.60333 15.8087 10.2931 10.7032 16.4169 7.26141V7.26395Z"
          fill="#3C9CD6"
        />
        <path
          d="M34.2006 39.3634C34.4739 39.3634 8.09091 43.1399 3.66352 21.1604C3.66352 21.1604 2.00037 12.7627 6.44309 7.4415C6.44309 7.4415 10.5128 9.88142 10.8398 16.0827C11.2971 24.7239 17.2957 39.2391 34.2006 39.3634Z"
          fill="url(#paint5_linear_17_4)"
        />
        <path
          d="M3.3288 17.7643C3.3288 17.7643 5.04815 28.6348 17.3698 36.1829C17.3698 36.1829 8.92628 32.0411 6.62699 28.6323C3.09887 23.4024 3.6507 19.8567 3.3288 17.7643Z"
          fill="#6387C5"
        />
        <path
          d="M4.83615 33.3473C11.6318 39.7311 23.1589 40.9714 34.2006 39.3634C34.2006 39.3634 17.8808 41.641 7.31171 28.1352C7.31171 28.1352 3.88323 23.235 -0.00256348 23.8894C-0.00256348 23.8894 0.0945177 28.8961 4.8336 33.3473H4.83615Z"
          fill="url(#paint6_linear_17_4)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_17_4"
          x1="18.1694"
          y1="27.3363"
          x2="60.9999"
          y2="27.3363"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4FC5F0" />
          <stop offset="1" stopColor="#6ABAE8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_17_4"
          x1="28.5366"
          y1="24.3967"
          x2="46.9675"
          y2="31.8622"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#71CCEE" />
          <stop offset="0.91" stopColor="#8ECFF2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_17_4"
          x1="19.2935"
          y1="26.2533"
          x2="55.8215"
          y2="26.2533"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8ED2EF" />
          <stop offset="1" stopColor="#AFDDF7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_17_4"
          x1="47.6003"
          y1="14.2439"
          x2="27.142"
          y2="24.7424"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7BC8EF" />
          <stop offset="1" stopColor="#6FC5EF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_17_4"
          x1="38.3623"
          y1="10.0235"
          x2="23.8499"
          y2="24.6414"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#30ADE0" />
          <stop offset="1" stopColor="#7AC5ED" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_17_4"
          x1="10.8756"
          y1="11.3854"
          x2="17.6428"
          y2="28.0073"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58B4E5" />
          <stop offset="1" stopColor="#7CB3E1" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_17_4"
          x1="3.77082"
          y1="26.8315"
          x2="18.2227"
          y2="35.9092"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A4D5F3" />
          <stop offset="1" stopColor="#95CEF0" />
        </linearGradient>
        <clipPath id="clip0_17_4">
          <rect width="61" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
