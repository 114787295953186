import { Link, Stack, Typography } from '@mui/material';
import environment from 'environments';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ p: '24px 16px 0px', mt: 'auto' }}
  >
    <Typography variant="caption">&copy; Snowpad | crafted by gh0st team</Typography>
    <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
      <Link
        component={RouterLink}
        to={environment.documentUrl}
        target="_blank"
        variant="caption"
        color="textPrimary"
      >
        Documentation
      </Link>
    </Stack>
  </Stack>
);

export default Footer;
