import { ethers } from 'ethers';
import moment from 'moment';
import numeral from 'numeral';
import { CustomQuery } from 'types/root';

export const momentDateFormat = 'DD/MM/yyyy HH:mm';

export const trimText = (text?: string, startSubStringLength = 5, endSubStringLength = 4) => {
  if (!text) return '';

  if (text) {
    return `${text.substring(0, startSubStringLength)}....${text.substring(
      text.length - endSubStringLength
    )}`;
  }
};

export const commaNumber = (number: string | number, decimals = 3) => {
  if (!number) {
    return number;
  }
  const format =
    decimals && !hasAllZeroesAfterDecimal(number) ? `0,0.${'0'.repeat(decimals)}` : '0,0';
  return numeral(number).format(format);
};

export const dateToUnixSeconds = (date: Date) => {
  return moment(date).unix();
};

export const dateToUtc = (date: Date) => {
  return moment(date).utc(true).format(momentDateFormat);
};

export const unixSecondsToUtc = (timestamp: number) => {
  const date = moment.unix(timestamp);
  return moment(date).utc(true).format(momentDateFormat);
};

export const queryParamsToString = (params: CustomQuery) => {
  if (!params) return '';
  const keys = Object.keys(params);
  const stringParam = keys
    .map((key, i) => {
      const symbol = i === 0 ? '?' : '&';
      if (!params[key]) return '';
      return `${symbol}${key}=${params[key]}`;
    })
    .join('');
  return stringParam;
};

export const hasAllZeroesAfterDecimal = (number: string | number) => {
  const strNumber = number.toString();
  const decimalPart = strNumber.split('.')[1];
  if (decimalPart === undefined) {
    return true;
  }
  return /^0+$/.test(decimalPart);
};

export const toWei = (value: number | string) => {
  return ethers.utils.parseEther(value.toString());
};

export const toEther = (value: ethers.BigNumberish) => {
  return +ethers.utils.formatEther(value);
};

export const toContractPercent = (value: number) => {
  return value * 100;
};

export const toPercent = (value: number) => {
  return value / 100;
};
