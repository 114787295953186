import { Blend2, BuyCrypto, DirectUp, Element, Home } from 'iconsax-react';
import { NavItemType } from 'types/menu';

const generalPages: NavItemType = {
  id: 'general-pages',
  title: 'General',
  type: 'group',
  children: [
    {
      id: 'home',
      title: 'Home',
      type: 'item',
      icon: Home,
      breadcrumbs: false,
      url: '/'
    }
  ]
};

const tokenPages: NavItemType = {
  id: 'token-pages',
  title: 'Token',
  type: 'group',
  children: [
    {
      id: 'create-token',
      title: 'Create Token',
      type: 'item',
      icon: BuyCrypto,
      url: '/token/create'
    },
    {
      id: 'mint-token',
      title: 'Mint Token',
      type: 'item',
      icon: Blend2,
      url: '/token/mint'
    }
  ]
};

const launchpadPages: NavItemType = {
  id: 'launchpad-pages',
  title: 'Launchpad',
  type: 'group',
  children: [
    {
      id: 'launchpad-list',
      title: 'Launchpad List',
      type: 'item',
      icon: Element,
      url: '/launchpad/list'
    },
    {
      id: 'create-launchpad',
      title: 'Create Launchpad',
      type: 'item',
      icon: DirectUp,
      url: '/launchpad/create'
    }
  ]
};

const airdropPages: NavItemType = {
  id: 'airdrop-pages',
  title: 'Airdrop',
  type: 'group',
  children: [
    {
      id: 'airdrop-list',
      title: 'Airdrop List',
      type: 'item',
      icon: Element,
      url: '/airdrop/list'
    },
    {
      id: 'create-airdrop',
      title: 'Create Airdrop',
      type: 'item',
      icon: DirectUp,
      url: '/airdrop/create'
    }
  ]
};

const menuItems: { items: NavItemType[] } = {
  items: [generalPages, tokenPages, launchpadPages, airdropPages]
};

export default menuItems;
