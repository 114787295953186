import MainLayout from 'components/@layout/main-layout';
import Loadable from 'components/Loadable';
import { ViewAirdropContextProvider } from 'contexts/ViewAirdropContext';
import { ViewLaunchpadContextProvider } from 'contexts/ViewLaunchpadContext';
import { lazy } from 'react';
import { Navigate } from 'react-router';

const HomePage = Loadable(lazy(() => import('pages/home/Home')));
// token
const CreateTokenPage = Loadable(lazy(() => import('pages/token/CreateToken')));
const MintTokenPage = Loadable(lazy(() => import('pages/token/MintToken')));
// launchpad
const CreateLaunchpadPage = Loadable(lazy(() => import('pages/launchpad/CreateLaunchpad')));
const LaunchpadListPage = Loadable(lazy(() => import('pages/launchpad/LaunchpadList')));
const ViewLaunchpadPage = Loadable(lazy(() => import('pages/launchpad/ViewLaunchpad')));
// airdrop
const CreateAirdropPage = Loadable(lazy(() => import('pages/airdrop/CreateAirdrop')));
const AirdropListPage = Loadable(lazy(() => import('pages/airdrop/AirdropList')));
const ViewAirdropPage = Loadable(lazy(() => import('pages/airdrop/ViewAirdrop')));

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <>
          <MainLayout />
        </>
      ),
      children: [
        {
          path: '',
          children: [
            {
              path: '',
              element: <HomePage />
            }
          ]
        },
        {
          path: 'token',
          children: [
            {
              path: 'create',
              element: <CreateTokenPage />
            },
            {
              path: 'mint',
              element: <MintTokenPage />
            }
          ]
        },
        {
          path: 'launchpad',
          children: [
            {
              path: 'create',
              element: <CreateLaunchpadPage />
            },

            {
              path: 'list',
              children: [
                {
                  path: '',
                  element: <LaunchpadListPage />
                },
                {
                  path: 'my-private-sale',
                  element: <LaunchpadListPage />
                },
                {
                  path: 'my-contributions',
                  element: <LaunchpadListPage />
                },
                {
                  path: 'my-own',
                  element: <LaunchpadListPage />
                },
                {
                  path: '*',
                  element: <Navigate to="" />
                }
              ]
            },
            {
              path: ':launchpadId',
              element: (
                <ViewLaunchpadContextProvider>
                  <ViewLaunchpadPage />
                </ViewLaunchpadContextProvider>
              )
            }
          ]
        },
        {
          path: 'airdrop',
          children: [
            {
              path: 'create',
              element: <CreateAirdropPage />
            },

            {
              path: 'list',
              children: [
                {
                  path: '',
                  element: <AirdropListPage />
                },
                {
                  path: 'my-airdrops',
                  element: <AirdropListPage />
                },
                {
                  path: 'my-own',
                  element: <AirdropListPage />
                },
                {
                  path: '*',
                  element: <Navigate to="" />
                }
              ]
            },
            {
              path: ':airdropId',
              element: (
                <ViewAirdropContextProvider>
                  <ViewAirdropPage />
                </ViewAirdropContextProvider>
              )
            }
          ]
        }
      ]
    }
  ]
};

export default MainRoutes;
