import { Addresses } from 'types/root';
import { jib } from 'utils/constants/chain';
import { Chain } from 'viem';

const launchpadFactoryAddresses: Addresses = {
  8899: process.env.SNOWPAD_LUNCHPAD_FACTORY || '0x512f0B2155C8F1b31F287CAbE1F99bE5dB24d0Dd'
};

const tokenFactoryAddresses: Addresses = {
  8899: process.env.SNOWPAD_TOKEN_FACTORY || '0x22Af56B212d4896DccDBA9a1E50Aa95C4364B0db'
};

const callHelperAddresses: Addresses = {
  8899: process.env.SNOWPAD_CALL_HELPER || '0x29824A60E7B4C42B9368E0C1a4C04F9250fDD573'
};

const airdropFactoryAddresses: Addresses = {
  8899: process.env.SNOWPAD_AIRDROP_FACTORY || '0x4A642317559Af6B8025dB0490455ce7580F38934'
};
const supportChains: [Chain, ...Chain[]] = [jib];

const environment = {
  mode: process.env.REACT_APP_MODE,
  supportChains,
  apiUrl:
    process.env.SNOWPAD_API_URL || 'https://asia-southeast1-snowpad-13fec.cloudfunctions.net/api',
  walletConnectProjectId: 'd58202e3f0854741f196484c107aef01',
  launchpadFactoryAddresses,
  tokenFactoryAddresses,
  callHelperAddresses,
  airdropFactoryAddresses,
  documentUrl: 'https://snowpad.gitbook.io/document',
  chainRpcUrl: process.env.SHOWPAD_CHAIN_RPC || 'https://rpc-l1.jibchain.net',
  fee: 1
};

export default environment;
