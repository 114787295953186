import moment from 'moment';
import { useEffect, useState } from 'react';
import { LaunchpadStatus } from 'types/launchpad';

export default function useLaunchpadStatus(startTime: number, endTime: number) {
  const [status, setStatus] = useState<LaunchpadStatus>(LaunchpadStatus.PEDDING);

  useEffect(() => {
    findLaunchpadStatus();
    const intervalId = setInterval(() => findLaunchpadStatus(), 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const findLaunchpadStatus = () => {
    const startTimeMoment = moment.unix(startTime);
    const endTimeMoment = moment.unix(endTime);
    const isBeforeStartTime = moment().isBefore(startTimeMoment);
    const isAfterEndTime = moment().isAfter(endTimeMoment);
    const isBetween = moment().isBetween(startTimeMoment, endTimeMoment);
    isBeforeStartTime && setStatus(LaunchpadStatus.UPCOMING);
    isBetween && setStatus(LaunchpadStatus.LIVE);
    isAfterEndTime && setStatus(LaunchpadStatus.ENDED);
  };

  return status;
}
