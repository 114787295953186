import { Airdrop__factory } from 'contracts/airdrop';
import useSigner from './useSigner';
import useWeb3Provider from './useWeb3Provider';

export default function useAirdropContract() {
  const signer = useSigner();
  const provider = useWeb3Provider();

  const getContractInstant = (address: string) => {
    if (!provider && !address) {
      return null;
    }

    const signerOrProvider = signer || provider;
    const airdrop = Airdrop__factory.connect(address, signerOrProvider);
    return airdrop;
  };

  return { getContractInstant };
}
