import { CreateLaunchpadPayload, Launchpad } from 'types/launchpad';
import { PageInfo } from 'types/root';
import axios from 'utils/axios';

export const createLaunchpadApi = async (
  payload: CreateLaunchpadPayload
): Promise<Launchpad | undefined> => {
  const { data } = await axios.post('/launchpads', { ...payload });
  return data;
};

export const getAllLaunchpadsApi = async (
  params?: string
): Promise<PageInfo<Launchpad[]> | undefined> => {
  const { data } = await axios.get(`/launchpads${params}`);
  return data;
};

export const getMyOwnLaunchpadsApi = async (
  params?: string
): Promise<PageInfo<Launchpad[]> | undefined> => {
  const { data } = await axios.get(`/launchpads/me${params}`);
  return data;
};

export const getMyParticipatingLaunchpadsApi = async (
  params?: string
): Promise<PageInfo<Launchpad[]> | undefined> => {
  const { data } = await axios.get(`/launchpads/me/participate${params}`);
  return data;
};

export const getMyPrivateSaleLaunchpadsApi = async (
  params?: string
): Promise<PageInfo<Launchpad[]> | undefined> => {
  const { data } = await axios.get(`/launchpads/me/private-sale${params}`);
  return data;
};

export const getLaunchpadApi = async (launchpadId: string): Promise<Launchpad | undefined> => {
  const { data } = await axios.get(`/launchpads/${launchpadId}`);
  return data;
};

export const buyerParticipateLaunchpadApi = async (
  launchpadId: string
): Promise<boolean | undefined> => {
  const { data } = await axios.post(`/launchpads/${launchpadId}/participate`);
  return data;
};

export const buyerWithdrawLaunchpadApi = async (
  launchpadId: string
): Promise<boolean | undefined> => {
  const { data } = await axios.post(`/launchpads/${launchpadId}/withdraw`);
  return data;
};

export const addWhitelistLaunchpadApi = async (
  launchpadId: string,
  whitelist: string[]
): Promise<boolean | undefined> => {
  const { data } = await axios.post(`/launchpads/${launchpadId}/add-whitelist`, { whitelist });
  return data;
};

export const removeWhitelistLaunchpadApi = async (
  launchpadId: string,
  whitelist: string[]
): Promise<boolean | undefined> => {
  const { data } = await axios.post(`/launchpads/${launchpadId}/remove-whitelist`, { whitelist });
  return data;
};

export const updateLaunchpadSaleTypeApi = async (
  launchpadId: string,
  isPrivateSale: boolean
): Promise<Launchpad | undefined> => {
  const { data } = await axios.patch(`/launchpads/${launchpadId}`, { isPrivateSale });
  return data;
};
