import { AxiosError } from 'axios';
import { BaseError } from 'wagmi';

export const errorMessage = (error: unknown): string | string[] => {
  if (error instanceof TypeError) {
    return error.message;
  } else if (error instanceof AxiosError) {
    return error?.response?.data?.data?.message;
  } else if (error instanceof BaseError) {
    return error?.shortMessage;
  } else if (typeof error === 'string') {
    return error;
  } else {
    const e = error as any; // eslint-disable-line
    if (!!e?.reason) {
      return `${e?.reason}`;
    }
    return e?.message || 'Something Wrong! Unknown error instance';
  }
};
