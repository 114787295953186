import { Backdrop, Fade, Modal, ModalProps } from '@mui/material';
import MainCard from 'components/MainCard';

type Props = ModalProps & {
  disablePadding?: boolean;
};

export default function BaseModal({ disablePadding, children, ...modalProps }: Props) {
  return (
    <Modal
      {...modalProps}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      sx={{ outline: 'none' }}
    >
      <Fade in={modalProps.open}>
        <MainCard modal darkTitle content={!disablePadding}>
          {children}
        </MainCard>
      </Fade>
    </Modal>
  );
}
