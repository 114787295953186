import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import environment from 'environments';
import { PropsWithChildren } from 'react';
import { WagmiProvider } from 'wagmi';
import { injected } from 'wagmi/connectors';

const queryClient = new QueryClient();

const projectId = environment.walletConnectProjectId;
const chains = [...environment.supportChains] as const;
const config = defaultWagmiConfig({
  connectors: [injected({ shimDisconnect: false })],
  chains,
  projectId,
  metadata: {
    name: 'Launchpad',
    description: 'Launchpad Dashboard',
    url: window.location.origin,
    icons: ['']
  },
  enableWalletConnect: false,
  enableInjected: true,
  enableEIP6963: false,
  enableCoinbase: false
});

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  themeVariables: {
    '--w3m-z-index': 1500
  }
});

export default function WagmiConfig({ children }: PropsWithChildren) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}
