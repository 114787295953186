import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'types/auth';

export type AccountReducerState = {
  user: User | null;
};

const initialState: AccountReducerState = {
  user: null
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<User>) => ({
      ...state,
      user: action.payload
    }),
    clearUserInfo: (state) => ({
      ...state,
      user: null
    })
  }
});

export const { setUserInfo, clearUserInfo } = accountSlice.actions;
export default accountSlice.reducer;
