import CommonLayout from 'components/@layout/common-layout';
import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const NotFound404 = Loadable(lazy(() => import('pages/miscellaneous/404')));

const routes: RouteObject = {
  path: '/',
  element: <CommonLayout />,
  children: [
    {
      path: '*',
      element: <NotFound404 />
    }
  ]
};

export default routes;
