import { JwtToken } from 'types/auth';
import axios from 'utils/axios';
import { tokenKey } from './constants/local-storage';

export const setCredentialTokens = (tokens?: JwtToken) => {
  if (tokens) {
    localStorage.setItem(tokenKey.accessToken, tokens.accessToken);
    localStorage.setItem(tokenKey.refreshToken, tokens.refreshToken);
    axios.defaults.headers.common.Authorization = `Bearer ${tokens.accessToken}`;
  } else {
    localStorage.removeItem(tokenKey.accessToken);
    localStorage.removeItem(tokenKey.refreshToken);
    delete axios.defaults.headers.common.Authorization;
  }
};

export const getCredentialTokens = () => {
  const accessToken = localStorage.getItem(tokenKey.accessToken);
  const refreshToken = localStorage.getItem(tokenKey.refreshToken);
  if (!!accessToken && !!refreshToken) {
    return { accessToken, refreshToken };
  }
  return null;
};
