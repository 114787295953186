export type Step1TokenFormValue = {
  tokenOnSale: string;
  buyingCurrency: string;
  launchpadFeeType: string;
};

// buyingCurrency: ethers.constants.AddressZero,
// tokenOnSale: token1.address,
// launchpadFeeType: 0,

export type Step2InfoFormValue = {
  presaleRate: string;
  listingRate: string;
  softCap: string;
  hardCap: string;
  minBuyPerUser: string;
  maxBuyPerUser: string;
  refundType: number;
  startTime: Date | null;
  endTime: Date | null;
  liquidityPercent: string;
  useVesting: boolean;
  firstBatchPercent: string;
  cycleDays: string;
  cycleBatchPercent: string;
};

// startTime: (await time.latest()) + 500,
// endTime: (await time.latest()) + 1000,
// minBuyPerUser: FEE,
// maxBuyPerUser: FIFTY_ETH,
// hardCap: TWO_HUNDRED_ETH,
// softCap: HUNDRED_ETH,
// presaleRate: 20,
// listingRate: 15,
// liquidityPercent: 60,
// refundType: 0,

export type Step3InfoFormValue = {
  logoUrl: string;
  website: string;
  facebook: string;
  twitter: string;
  github: string;
  telegram: string;
  instagram: string;
  discord: string;
  reddit: string;
  youtubeVideo: string;
  description: string;
};

export type CurrencyInfo = {
  name: string;
  symbol: string;
  decimals: number;
};

export type LaunchpadInfo = {
  buyingCurrency: string;
  tokenOnSale: string;
  startTime: number;
  endTime: number;
  minBuyPerUser: number;
  maxBuyPerUser: number;
  hardCap: number;
  softCap: number;
  presaleRate: number;
  listingRate: number;
  liquidityPercent: number;
  launchpadFeeType: number;
  refundType: number;
};

export type VestingInfo = {
  useVesting: boolean;
  firstBatchPercent: number;
  cycleDays: number;
  cycleBatchPercent: number;
};

export type CreateLaunchpadPayload = LaunchpadInfo &
  Step3InfoFormValue &
  VestingInfo & {
    owner: string;
    publicAddress: string;
    tokenOnSaleInfo: CurrencyInfo;
    buyingCurrencyInfo: CurrencyInfo;
    totalToken: number;
    lockupTime: number;
  };

export type Launchpad = CreateLaunchpadPayload & {
  id: string;
  createdAt: string;
  updatedAt: string;
};

export enum LaunchpadStatus {
  PEDDING = 0,
  UPCOMING = 1,
  LIVE = 2,
  ENDED = 3
}
