import { authSignInApi, authValidateApi } from 'apis/auth';
import { getUserInfoApi } from 'apis/users';
import Loader from 'components/Loader';
import { PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { setUserInfo } from 'store/reducers/account';
import useBoolean from 'utils/hooks/useBoolean';
import { useDeepEffect } from 'utils/hooks/useDeepEffect';
import useWallet from 'utils/hooks/useWallet';
import { getCredentialTokens, setCredentialTokens } from 'utils/local-storage';
import { catchErrorSnackbar } from 'utils/snackbar';
import { useAccount, useSignMessage } from 'wagmi';

export default function AuthPortal({ children }: PropsWithChildren) {
  const { address, isConnecting } = useAccount();

  const { signMessageAsync } = useSignMessage();
  const dispatch = useDispatch();
  const { disconnectWallet } = useWallet();
  const [initializing, initializingStart, initializingDone] = useBoolean(true);
  const [signing, signingStart, signingDone] = useBoolean(true);

  useDeepEffect(() => {
    (async () => {
      try {
        initializingStart();
        signingStart();
        const isSignIn = !!getCredentialTokens();
        if (!!address && !isSignIn) {
          await signWalletMessage();
        }
        if (!!address && isSignIn) {
          await setCurrentUser();
        }
      } catch (error) {
        catchErrorSnackbar(error);
        disconnectWallet();
      } finally {
        initializingDone();
        signingDone();
      }
    })();
  }, [address]);

  const signWalletMessage = async () => {
    if (!address) throw new Error('Wallet address not found');
    const validatePayload = await authValidateApi(address);
    if (!validatePayload) throw new Error('Validate payload not found');
    const signature = await signMessageAsync({
      message: validatePayload.msg
    });
    const signInPayload = await authSignInApi(address, signature);
    if (!signInPayload) throw new Error('User not found');
    dispatch(setUserInfo(signInPayload.user));
    setCredentialTokens(signInPayload.tokens);
  };

  const setCurrentUser = async () => {
    const storageToken = getCredentialTokens();
    if (!storageToken) throw new Error('Credential not found');
    setCredentialTokens(storageToken);
    const userInfo = await getUserInfoApi();
    if (!userInfo) throw new Error('User not found');
    dispatch(setUserInfo(userInfo));
  };

  if (initializing || signing || isConnecting) {
    return <Loader />;
  }

  return <>{children}</>;
}
