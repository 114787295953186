import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { windowScrollToTop } from 'utils/window';

const ScrollTopPortal = ({ children }: { children: ReactElement | null }) => {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    windowScrollToTop();
  }, [pathname]);

  return children || null;
};

export default ScrollTopPortal;
